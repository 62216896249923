import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import CredoLogo from 'src/assets/images/credo-logo.svg';
import { Flex } from 'src/components';
import { useNavigate, useLocation } from 'react-router-dom';
import { ACTIVE_PARTIES, HOME, INVOICES } from 'src/utils';
import { useTheme, alpha } from '@mui/material';
import {
  useFetchSupplierProfileDetailsQuery,
  getMobileNumber,
  useAppSelector,
} from 'src/redux';
import { Typography } from 'src/components';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { BOX_SHADOW } from 'src/theme';

export type NavProps = {
  OnProfileClick?: React.MouseEventHandler<HTMLElement>;
  isRegistrationOngoing: boolean;
};

const tabsObjectMap: { [key: number]: string } = {
  0: HOME,
  1: ACTIVE_PARTIES,
  2: INVOICES,
};

export default function Navbar({
  OnProfileClick,
  isRegistrationOngoing,
}: NavProps) {
  const navigate = useNavigate();
  const { palette, spacing } = useTheme();
  const location = useLocation();
  const activeTabFromParams = location.pathname.split('/')[1];

  const activeTab = Object.keys(tabsObjectMap).find((objectKey) => {
    const numberCastedObjectKey = Number(objectKey);
    return tabsObjectMap[numberCastedObjectKey] === `/${activeTabFromParams}`;
  });

  const mobileNumber = useAppSelector(getMobileNumber);
  const { data: profileData } = useFetchSupplierProfileDetailsQuery({
    mobileNumber,
  });
  const onTabChange = (_: React.SyntheticEvent, newValue: number) => {
    const nextTab = tabsObjectMap[newValue];
    navigate(nextTab);
  };

  return (
    <Box sx={{ flexGrow: 1, background: 'white' }}>
      <AppBar
        position="static"
        color="transparent"
        sx={{ boxShadow: BOX_SHADOW }}
      >
        <Toolbar>
          <Flex justifyContent="space-between" flexGrow={1} alignItems="center">
            <Box sx={{ cursor: 'pointer' }}>
              <img
                src={CredoLogo}
                alt="Credochain Logo"
                onClick={() => navigate(`${HOME}?activetab=dashboard`)}
              />
            </Box>
            {!isRegistrationOngoing && (
              <Flex alignItems={'center'}>
                <Typography className="!mr-2" color="primary" fontWeight="bold">
                  {profileData?.supplierName}
                </Typography>
                <Flex
                  sx={{
                    padding: spacing(2.5),
                    borderRadius: '100%',
                    height: spacing(3),
                    width: spacing(3),
                    background: alpha(palette.primary.main, 0.15),
                    cursor: 'pointer',
                  }}
                  onClick={OnProfileClick}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    color="primary"
                    fontWeight="bold"
                    fontSize="1.2rem"
                  >
                    {profileData?.supplierName?.[0] ?? ''}
                  </Typography>
                </Flex>
              </Flex>
            )}
          </Flex>
        </Toolbar>
        {!isRegistrationOngoing && (
          <Tabs
            value={Number(activeTab)}
            onChange={onTabChange}
            centered
            sx={{
              mx: '10%',
              '.MuiTabs-centered': {
                justifyContent: 'space-around',
              },
              height: 55,
            }}
          >
            <Tab
              value={0}
              icon={<HomeOutlinedIcon fontSize="medium" />}
              iconPosition="start"
              label="Home"
            />
            <Tab
              label="Active Parties"
              value={1}
              icon={<GroupOutlinedIcon fontSize="medium" />}
              iconPosition="start"
            />
            <Tab
              label="Invoices"
              value={2}
              icon={<ArticleOutlinedIcon fontSize="medium" />}
              iconPosition="start"
            />
          </Tabs>
        )}
      </AppBar>
    </Box>
  );
}
