import { ButtonBase, Grid, useTheme } from '@mui/material';
import { Card, Flex, Typography, FetchingSkeleton } from 'src/components';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import {
  useFetchSupplierProfileDetailsQuery,
  getSupplierId,
  useAppSelector,
  useAppDispatch,
  clearUserData,
  getPartyUniqueId,
  getMobileNumber,
} from 'src/redux';
import { useNavigate } from 'react-router-dom';
import { INDEX } from 'src/utils';

const UserProfile = () => {
  const { spacing, palette } = useTheme();
  const supplierId = useAppSelector(getSupplierId);
  const mobileNumber = useAppSelector(getMobileNumber);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const uniqueSupplierId = useAppSelector(getPartyUniqueId);
  const { data: profileData, isFetching: isProfileDataFetching } =
    useFetchSupplierProfileDetailsQuery({ mobileNumber });
  if (isProfileDataFetching) {
    return <FetchingSkeleton />;
  }

  const onLogout = () => {
    dispatch(clearUserData());
    navigate(INDEX);
  };

  const {
      businessAddress,
      bankDetails,
      supplierName,
      supplierEmail,
      supplierMobileNumber,
    } = profileData || {},
    {
      accountHolderName,
      actualAccountNumber,
      ifsc,
      branchName,
      bankName,
      isFundActive,
    } = bankDetails || {},
    { addr1, addr2, streetOrLandmark, city, state, pincode } =
      businessAddress || {};

  return (
    <>
      <Card
        sx={{
          marginBottom: spacing(2),
        }}
      >
        <Grid
          container
          gap={spacing(2)}
          sx={{
            my: spacing(1),
          }}
        >
          <Grid item xs={5}>
            <Typography fontWeight="bold">Name</Typography>
            <Typography>{supplierName}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography fontWeight="bold">Phone</Typography>
            <Typography>{supplierMobileNumber}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontWeight="bold">Email ID</Typography>
            <Typography>{supplierEmail}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography fontWeight="bold">Business Address</Typography>
            <Typography>
              {addr1} {addr2}
            </Typography>
            <Typography>
              {streetOrLandmark}, {city}
            </Typography>
            <Typography>
              {state}, {pincode}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Card
        sx={{
          marginBottom: spacing(2),
        }}
      >
        <Flex
          justifyContent="space-between"
          sx={{
            pb: spacing(0.5),
            mb: spacing(2),
            borderBottom: `1px solid ${palette.lightGray}`,
          }}
        >
          <Typography color="primary" fontSize="1.1rem">
            Bank Details
          </Typography>
        </Flex>
        <Grid
          container
          gap={spacing(2)}
          sx={{
            my: spacing(1),
          }}
        >
          <Grid item xs={11}>
            <Typography fontWeight="bold">Account Number</Typography>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {actualAccountNumber}
              {isFundActive ? (
                <CheckCircleRoundedIcon
                  sx={{
                    color: palette.turquoise,
                    marginLeft: '0.2rem',
                    fontSize: '1.2rem',
                  }}
                />
              ) : null}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography fontWeight="bold">Account Holder Name</Typography>
            <Typography>{accountHolderName}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography fontWeight="bold">IFSC Code</Typography>
            <Typography>{ifsc}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography fontWeight="bold">Bank Name</Typography>
            <Typography>{bankName}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography fontWeight="bold">Bank Branch</Typography>
            <Typography>{branchName}</Typography>
          </Grid>
        </Grid>
      </Card>
      <Flex justifyContent={'center'}>
        <ButtonBase
          sx={{
            color: palette.red,

            margin: '2rem',
            fontWeight: 'bold',
          }}
          onClick={onLogout}
        >
          Log out
        </ButtonBase>
      </Flex>
    </>
  );
};

export default UserProfile;
