import { Box, Card, useTheme } from '@mui/material';
import { useState, useMemo } from 'react';
import {
  Typography,
  FetchingSkeleton,
  Flex,
  BuyerLogo,
  DateRange,
} from 'src/components';
import { withPageInternals } from 'src/hoc';
import LinearProgress from '@mui/material/LinearProgress';
import {
  useFetchBuyerDetailsQuery,
  useAppSelector,
  useAppDispatch,
  getSupplierId,
  getAccessToken,
  toggleSnackbar,
  useFetchActivePartiesQuery,
  getAccessUserId,
} from 'src/redux';
import { ERRORS, REQUEST_SOURCE } from 'src/constants';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import PaymentView from './PaymentView';
import InvoiceView from './InvoiceView';
import { KeyboardBackspace } from '@mui/icons-material';
import { rupeeDecimalFormatter } from 'src/utils';
import { useNavigate } from 'react-router-dom';
import { ACTIVE_PARTIES } from 'src/utils';
import { BUYER_LIMIT_STATUS_CAPSULE } from 'src/constants';
import ExcelDownload from 'src/assets/images/excel.png';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { DateRangeOnChange } from 'src/components/common/DateRange';

const ActiveParties = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { spacing } = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const supplierId = useAppSelector(getSupplierId);
  const [date, setDate] = useState<{
    startDate: string | null;
    endDate: string | null;
  }>({
    startDate: null,
    endDate: null,
  });

  const { data: activePartiesData } = useFetchActivePartiesQuery({
    pageNumber: 1,
    supplierId,
    searchBy: '',
    searchValue: '',
  });

  const userId = activePartiesData?.data?.list?.[0].userId || '';

  const onToggleSwitch = (
    _: React.MouseEvent<HTMLElement>,
    activeTab: number
  ) => {
    if (activeTab !== null) {
      setActiveTab(activeTab);
    }
  };

  const token = useAppSelector(getAccessToken);
  const userid = useAppSelector(getAccessUserId);
  const headers = {
    Accept: 'application/json',
    Authorization: 'Bearer ' + token,
    'Content-Type': 'multipart/formData',
    'X-CLIENT-NAME': REQUEST_SOURCE,
    'X-USER-ID': userid,
  };
  const excelDownload = async () => {
    try {
      const response = await axios.get<Blob>(
        `${process.env.REACT_APP_BASE_URL_PEMANT_C1}/invoice-management/v2/dashboard/download/INVOICE_PAYMENT_EXCEL/${supplierId}/${userId}`,
        {
          headers,
          responseType: 'blob',
        }
      );
      const contentDispositionHeader =
        response?.headers?.['content-disposition'];
      const matchFileName = contentDispositionHeader.match(
        /filename=["']?([^"']+)["']?/
      );
      fileDownload(
        response.data,
        `${matchFileName ? matchFileName[1] : 'excel_file.xlsx'}`
      );
    } catch (error) {
      dispatch(
        toggleSnackbar({
          showSnackBar: true,
          snackBarMessage: ERRORS.SOMETHING_WRONG,
          snackBarType: 'error',
        })
      );
    }
  };

  const { data: buyerData, isFetching: isBuyerDataFetching } =
    useFetchBuyerDetailsQuery({
      supplierId,
      userId,
    });

  const linerProgressVal = useMemo(() => {
    const _totalLimit = buyerData?.totalLimit || 0;
    const _leftLimit = buyerData?.leftLimit || 0;
    const _consumedLimit = _totalLimit - _leftLimit;
    return (_consumedLimit / _totalLimit) * 100;
  }, [buyerData]);

  if (isBuyerDataFetching) {
    return <FetchingSkeleton />;
  }
  const handleDateChange = ({ startDate, endDate }: DateRangeOnChange) => {
    setDate({
      startDate,
      endDate,
    });
  };

  const capsuleHandler = (type: string) => {
    return BUYER_LIMIT_STATUS_CAPSULE?.map((item: any) => {
      return item.status === buyerData?.limitStatus
        ? item[type]
        : 'transparent';
    }).find((value) => value !== 'transparent');
  };

  const resetDate = () => {
    setDate({
      startDate: null,
      endDate: null,
    });
  };

  return (
    <>
      <Flex alignItems={'center'} className="gap-4 my-6">
        <Typography fontSize="22px" fontWeight="bold">
          {buyerData?.buyerName}
        </Typography>
      </Flex>
      <Card className="p-8">
        <Box className="grid grid-cols-3 gap-5">
          <Box className="col-span-1">
            <Flex className="gap-2" alignItems={'center'}>
              <BuyerLogo name={buyerData?.buyerName} />
              <Box>
                <Typography fontWeight="bold" color="primary">
                  {buyerData?.buyerName || '-'}
                </Typography>
                <Typography>{buyerData?.buyerMobileNumber || '-'}</Typography>
              </Box>
            </Flex>
          </Box>
          <Box className="col-span-1">
            <Typography fontWeight="bold">GST Number</Typography>
            <Typography>{buyerData?.buyerGstin || '-'}</Typography>
          </Box>
        </Box>
        <Box className="grid grid-cols-4 gap-5 my-8">
          <Box className="col-span-1">
            <Typography fontWeight="bold">Total Invoices</Typography>
            <Typography>{buyerData?.totalInvoice}</Typography>
          </Box>
          <Box className="col-span-1">
            <Typography fontWeight="bold">Paid Invoice</Typography>
            <Typography>{buyerData?.paidInvoice}</Typography>
          </Box>
          <Box className="col-span-1">
            <Typography fontWeight="bold">Unpaid Invoice</Typography>
            <Typography>{buyerData?.unpaidInvoice}</Typography>
          </Box>
          <Box className="col-span-1">
            <Typography fontWeight="bold">Overdue Invoices</Typography>
            <Typography>{buyerData?.overdueInvoice}</Typography>
          </Box>
          <Box className="col-span-1">
            <Typography fontWeight="bold">Total Amount</Typography>
            <Typography>
              {rupeeDecimalFormatter(buyerData?.totalAmount)}
            </Typography>
          </Box>
          <Box className="col-span-1">
            <Typography fontWeight="bold">Paid Amount</Typography>
            <Typography>
              {rupeeDecimalFormatter(buyerData?.paidAmount)}
            </Typography>
          </Box>
          <Box className="col-span-1">
            <Typography fontWeight="bold">Unpaid Amount</Typography>
            <Typography>
              {rupeeDecimalFormatter(buyerData?.unpaidAmount)}
            </Typography>
          </Box>
          <Box className="col-span-1">
            <Typography fontWeight="bold">Overdue Amount</Typography>
            <Typography>
              {rupeeDecimalFormatter(buyerData?.overdueAmount)}
            </Typography>
          </Box>
        </Box>
        <Flex>
          <Box sx={{ width: spacing(50) }}>
            <Flex justifyContent={'space-between'} className="mb-2">
              <Box>
                <Typography fontWeight="bold">Total Limit</Typography>
                <Typography>
                  {rupeeDecimalFormatter(buyerData?.totalLimit)}
                </Typography>
              </Box>
              <Box>
                <Typography fontWeight="bold" className="!text-right">
                  Limit Left
                </Typography>
                <Typography>
                  {rupeeDecimalFormatter(buyerData?.leftLimit)}
                </Typography>
              </Box>
            </Flex>
            <LinearProgress
              variant="determinate"
              value={linerProgressVal}
              color="success"
              sx={{
                height: 6,
                borderRadius: 2,
              }}
            />
          </Box>
          <Box sx={{ width: spacing(20) }}>
            <Flex>
              <Typography
                sx={{
                  borderRadius: 10,
                  p: `${spacing(0.5)} ${spacing(2.5)}`,
                  fontSize: '1rem',
                  ml: '2rem',
                  color: `${capsuleHandler('colour')}`,
                  backgroundColor: `${capsuleHandler('backgroundColor')}`,
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  minWidth: '6rem',
                }}
              >
                {buyerData?.limitStatus}
              </Typography>
            </Flex>
          </Box>
        </Flex>
      </Card>

      <Card className="p-8 my-8" sx={{ overflow: 'visible' }}>
        <Typography fontSize="22px" fontWeight="bold">
          <Flex justifyContent={'space-between'}>
            <div>Invoices</div>
            {activeTab === 0 && (
              <img
                className="mx-1"
                src={ExcelDownload}
                height={18}
                width={28}
                onClick={excelDownload}
              />
            )}
          </Flex>
        </Typography>
        <Flex justifyContent={'space-between'} className="my-4">
          <ToggleButtonGroup
            value={activeTab}
            onChange={onToggleSwitch}
            color="primary"
            exclusive
          >
            <ToggleButton value={0}>Payment View</ToggleButton>
            <ToggleButton value={1}> Invoice View</ToggleButton>
          </ToggleButtonGroup>
          <DateRange
            startDate={date.startDate}
            endDate={date.endDate}
            onChange={handleDateChange}
          />
        </Flex>
        {activeTab === 0 && (
          <PaymentView
            startDate={date.startDate}
            endDate={date.endDate}
            resetDate={resetDate}
          />
        )}
        {activeTab === 1 && (
          <InvoiceView
            startDate={date.startDate}
            endDate={date.endDate}
            resetDate={resetDate}
          />
        )}
      </Card>
    </>
  );
};

export default withPageInternals(ActiveParties);
