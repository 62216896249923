import { withPageInternals } from 'src/hoc';
import { Card, Flex, Typography, DateRange } from 'src/components';
import { useState } from 'react';
import InvoiceView from './InvoiceView';
import PaymentView from './PaymentView';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {
  useAppSelector,
  getSupplierId,
  getAccessToken,
  useAppDispatch,
  toggleSnackbar,
  getAccessUserId,
} from 'src/redux';
import ExcelDownload from 'src/assets/images/excel.png';
import fileDownload from 'js-file-download';
import axios from 'axios';
import { ERRORS, REQUEST_SOURCE } from 'src/constants';
import { DateRangeOnChange } from 'src/components/common/DateRange';

const InvoicesScreen = () => {
  const [activeTab, setActiveTab] = useState(0);
  const supplierId = useAppSelector(getSupplierId);
  const dispatch = useAppDispatch();
  const [date, setDate] = useState<{
    startDate: string | null;
    endDate: string | null;
  }>({
    startDate: null,
    endDate: null,
  });
  const onToggleSwitch = (
    _: React.MouseEvent<HTMLElement>,
    activeTab: number
  ) => {
    if (activeTab !== null) {
      setActiveTab(activeTab);
    }
  };

  const handleDateChange = ({ startDate, endDate }: DateRangeOnChange) => {
    setDate({
      startDate,
      endDate,
    });
  };

  const token = useAppSelector(getAccessToken);
  const userId = useAppSelector(getAccessUserId);
  const headers = {
    Accept: 'application/json',
    Authorization: 'Bearer ' + token,
    'Content-Type': 'multipart/formData',
    'X-CLIENT-NAME': REQUEST_SOURCE,
    'X-USER-ID': userId,
  };
  const excelDownload = async () => {
    try {
      const response = await axios.get<Blob>(
        `${process.env.REACT_APP_BASE_URL_PEMANT_C1}/invoice-management/v2/dashboard/download/INVOICE_PAYMENT_EXCEL/${supplierId}`,
        {
          headers,
          responseType: 'blob',
        }
      );
      const contentDispositionHeader =
        response?.headers?.['content-disposition'];
      const matchFileName = contentDispositionHeader.match(
        /filename=["']?([^"']+)["']?/
      );
      fileDownload(
        response.data,
        `${matchFileName ? matchFileName[1] : 'excel_file.xlsx'}`
      );
    } catch (error) {
      dispatch(
        toggleSnackbar({
          showSnackBar: true,
          snackBarMessage: ERRORS.SOMETHING_WRONG,
          snackBarType: 'error',
        })
      );
    }
  };

  const resetDate = () => {
    setDate({
      startDate: null,
      endDate: null,
    });
  };

  return (
    <>
      <Card>
        <Typography fontSize="22px" fontWeight="bold">
          <Flex justifyContent={'space-between'}>
            <div>Invoices</div>
            {activeTab === 0 && (
              <img
                className="mx-1"
                src={ExcelDownload}
                height={18}
                width={28}
                onClick={excelDownload}
              />
            )}
          </Flex>
        </Typography>
        <Flex justifyContent={'space-between'} className="my-5">
          <ToggleButtonGroup
            value={activeTab}
            onChange={onToggleSwitch}
            color="primary"
            exclusive
          >
            <ToggleButton value={0}>Payment View</ToggleButton>
            <ToggleButton value={1}>Invoice View</ToggleButton>
          </ToggleButtonGroup>
          <DateRange
            startDate={date.startDate}
            endDate={date.endDate}
            onChange={handleDateChange}
          />
        </Flex>
        {activeTab === 0 && (
          <PaymentView
            startDate={date.startDate}
            endDate={date.endDate}
            resetDate={resetDate}
          />
        )}
        {activeTab === 1 && (
          <InvoiceView
            startDate={date.startDate}
            endDate={date.endDate}
            resetDate={resetDate}
          />
        )}
      </Card>
    </>
  );
};

export default withPageInternals(InvoicesScreen);
