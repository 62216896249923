/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Container, Navbar, UploadFloatingButton } from 'src/components';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import { INDEX, OTP_VERIFY, PROFILE } from 'src/utils';
import { getUser, useAppSelector } from 'src/redux';
import { Box } from '@mui/material';

const withPageInternals =
  <
    T extends {
      currentUser: any;
    }
  >(
    Component: React.ComponentType<T>
  ) =>
  (props: T) => {
    //HOOKS
    const navigate = useNavigate();
    const location = useLocation();
    const user = useAppSelector(getUser);
    const routePath = location.pathname.replace('/', ''); //This one is use to handle upload invoices icon which is showing on login and logout
    //DERIVED VALUES
    const isRegistrationOngoing = [INDEX, OTP_VERIFY].includes(
      location.pathname
    );
    const isLogin = user.userBEData.userId;

    if (!isLogin && !isRegistrationOngoing) {
      return <Navigate to={INDEX} />;
    }

    return (
      <Box className="bg-[#F2F3F7] min-h-screen flex-1">
        <Navbar
          OnProfileClick={() => navigate(PROFILE)}
          isRegistrationOngoing={isRegistrationOngoing}
        />
        <Container>
          <Component {...props} isRegistrationOngoing={isRegistrationOngoing} />
        </Container>
        {routePath === '' ||
        routePath === 'profile' ||
        routePath === 'otp-verify' ? null : (
          <UploadFloatingButton />
        )}
      </Box>
    );
  };

export default withPageInternals;
