import React, { useEffect } from 'react';
import { Box, ButtonBase, useTheme } from '@mui/material';
import { COLORS } from 'src/theme';
import { useState } from 'react';
import { Button, Flex, Typography } from 'src/components';
import { set } from 'lodash';
import { ERRORS, REQUEST_SOURCE } from 'src/constants';
import { alpha } from '@mui/system';
import {
  getAccessToken,
  getAccessUserId,
  getSupplierId,
  toggleSnackbar,
  useAppDispatch,
  useAppSelector,
  useFetchBuyerDetailsQuery,
} from 'src/redux';
import axios from 'axios';
import {
  useUploadInvoiceDataMutation,
  toggleLoader,
  useLazyFetchActivePartiesQuery,
} from 'src/redux';
import { API_STATUS_CODE } from 'src/constants';
import InvoiceForm from './InvoiceForm';
import { INVOICE_UPLOAD_MEDIUM_ARRAY } from 'src/constants';
import { useParams, useLocation } from 'react-router-dom';

export type InvoiceDataType = {
  invoiceAmount: string;
  invoiceDate: string;
  invoiceId: string;
  invoiceNumber: string;
  partialPayment: boolean;
  userId: string;
  mediumType: string; // Make this a Enum,
  s3Url: string;
  partyName: string;
};

type UploadModalType = {
  closeModal: (e: boolean) => void;
};

const UploadInvoices = ({ closeModal }: UploadModalType) => {
  const invoiceEmptyObject = {
    invoiceAmount: '',
    invoiceDate: '',
    invoiceId: '',
    invoiceNumber: '',
    partialPayment: false,
    userId: '',
    mediumType: INVOICE_UPLOAD_MEDIUM_ARRAY[0].value,
    s3Url: '',
    partyName: '',
  };
  const [emptyObject, setEmptyObject] = useState(invoiceEmptyObject);
  const [invoiceDetails, setInvoiceDetails] = useState<InvoiceDataType[]>([
    emptyObject,
  ]);
  const dispatch = useAppDispatch();
  const { spacing, palette } = useTheme();
  const [sendInvoicesQuery] = useUploadInvoiceDataMutation();
  const supplierId = useAppSelector(getSupplierId);
  const token = useAppSelector(getAccessToken);
  const userId = useAppSelector(getAccessUserId);
  const [activePartiesFetch, { data: activePartiesData }] =
    useLazyFetchActivePartiesQuery();
  const { userId: userIdParam } = useParams();
  const location = useLocation();
  const invoiceTab = location.pathname.replace('/', '');
  const { data: buyerData, refetch } = useFetchBuyerDetailsQuery(
    {
      supplierId,
      userId: userIdParam as string,
    },
    {
      skip: !userIdParam,
    }
  );

  useEffect(() => {
    (async () => {
      if (userId) {
        const { data: activePartiesData } = await activePartiesFetch({
          pageNumber: 1,
          supplierId,
          searchBy: '',
          searchValue: (buyerData?.buyerMobileNumber as string) || '',
        });
        setEmptyObject({
          ...emptyObject,
          partyName: activePartiesData?.data?.list[0].partyName || '',
        });
        setInvoiceDetails([
          {
            ...emptyObject,
            partyName: activePartiesData?.data?.list[0].partyName || '',
          },
        ]);
      }
    })();
  }, []);
  const onInvoiceSend = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(toggleLoader(true));
    const invoicesList = invoiceDetails.map(
      ({ s3Url, mediumType, ...keepAttributes }) => {
        // when user is in active parties user Id is this else for bulk invoices it come from keepAttributes.

        return {
          invoiceAmount: Number(keepAttributes.invoiceAmount),
          invoiceDate: keepAttributes.invoiceDate,
          invoiceId: keepAttributes.invoiceId,
          invoiceNumber: keepAttributes.invoiceNumber.trim(),
          partialPayment: Boolean(keepAttributes.partialPayment),
          userId: activePartiesData?.data?.list?.[0]?.userId ?? '', // vendor dashboard buyer is always one. so i removed check to handle userId by routhpath
        };
      }
    );
    try {
      const response = await sendInvoicesQuery({
        list: invoicesList,
        supplierId: supplierId,
      }).unwrap();
      if (response.code === API_STATUS_CODE.OK) {
        if (invoiceTab.includes('active-parties')) {
          refetch();
        }
        closeModal(false);
        dispatch(
          toggleSnackbar({
            showSnackBar: true,
            snackBarMessage: ERRORS.INVOICE_SEND,
            snackBarType: 'success',
          })
        );
      } else {
        dispatch(
          toggleSnackbar({
            showSnackBar: true,
            snackBarMessage: response?.message,
            snackBarType: 'error',
          })
        );
      }
    } catch (error) {
      dispatch(
        toggleSnackbar({
          showSnackBar: true,
          snackBarMessage: ERRORS.SOMETHING_WRONG,
          snackBarType: 'error',
        })
      );
    }
    dispatch(toggleLoader(false));
  };

  function formatDate(dateString: string): string {
    if (dateString) {
      const [day, month, year] = dateString.split('-');
      return `${day}/${month}/${year}`;
    } else {
      return '';
    }
  }

  const onFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const files = event.target.files;
    const formData = new FormData();
    if (files && files?.length > 0) {
      formData.append('file', files[0]);
    }
    const headers = {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token,
      'Content-Type': 'multipart/formData',
      'X-CLIENT-NAME': REQUEST_SOURCE,
      'X-USER-ID': userId,
    };

    if (formData) {
      dispatch(toggleLoader(true));
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL_PEMANT_C1}/invoice-management/v2/dashboard/invoice-image/${supplierId}`,
          formData,
          {
            headers,
          }
        )
        .then(({ data }) => {
          dispatch(toggleLoader(false));
          if (data.invoiceId) {
            const scannedData = data.scannedInvoiceResponse.data;
            set(invoiceDetails, `[${index}].invoiceId`, data.invoiceId);
            set(invoiceDetails, `[${index}].s3Url`, data.s3Url);

            if (scannedData) {
              set(
                invoiceDetails,
                `[${index}].invoiceAmount`,
                scannedData?.total !== null ? scannedData.total : ''
              );
              set(
                invoiceDetails,
                `[${index}].invoiceNumber`,
                scannedData?.invoiceNo !== null ? scannedData?.invoiceNo : ''
              );
              set(
                invoiceDetails,
                `[${index}].buyerGST`,
                scannedData?.buyerGST !== null ? scannedData.buyerGST : ''
              );
              set(
                invoiceDetails,
                `[${index}].invoiceDate`,
                scannedData?.invoiceDate !== null
                  ? formatDate(scannedData?.invoiceDate)
                  : ''
              );
            }
            setInvoiceDetails([...invoiceDetails]);
            dispatch(
              toggleSnackbar({
                showSnackBar: true,
                snackBarMessage: ERRORS.INVOICE_UPLOADED,
                snackBarType: 'success',
              })
            );
          } else {
            dispatch(
              toggleSnackbar({
                showSnackBar: true,
                snackBarMessage: data?.message,
                snackBarType: 'error',
              })
            );
          }
        })
        .catch((err) => {
          console.error(err);
          dispatch(
            toggleSnackbar({
              showSnackBar: true,
              snackBarMessage: ERRORS.SOMETHING_WRONG,
              snackBarType: 'error',
            })
          );
        });
    }
  };

  const onChange = (name: string, value: string, index: number) => {
    const invoiceCopy = [...invoiceDetails];
    invoiceCopy[index] = { ...invoiceCopy[index], [name]: value };
    setInvoiceDetails(invoiceCopy);
  };

  const onDelete = (index: number) => {
    const invoiceDetailsCopy = [...invoiceDetails];
    invoiceDetailsCopy.splice(index, 1); // Remove the invoice at the given index
    setInvoiceDetails(invoiceDetailsCopy);
  };

  const onAddInvoice = () => {
    setInvoiceDetails([...invoiceDetails, { ...emptyObject }]); //When we add a new invoice, make sure to create a fresh object and push it to the invoiceDetails array so it won't affect previous entry.
  };
  const removeImage = (index: number) => {
    const invoiceCopy = [...invoiceDetails];
    invoiceCopy[index] = { ...invoiceCopy[index], ...emptyObject }; //Remove the invoice Details at the given index
    setInvoiceDetails(invoiceCopy);
  };

  console.log(invoiceDetails, 'invoiceDetails');
  return (
    <form onSubmit={onInvoiceSend}>
      {invoiceDetails?.map((invoice, index) => {
        return (
          <InvoiceForm
            key={`invoice-form${index}`}
            onDelete={onDelete}
            index={index}
            invoiceObject={invoice}
            onChange={onChange}
            onFileUpload={onFileUpload}
            removeImage={removeImage}
          />
        );
      })}
      <ButtonBase
        sx={{
          color: palette.primary.main,
          fontWeight: 'bold',
          marginBottom: '1rem',
          px: spacing(2),
          mt: spacing(2),
        }}
        onClick={onAddInvoice}
      >
        + Add new invoices
      </ButtonBase>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        sx={{
          backgroundColor: alpha(COLORS.PRIMARY, 0.1),
          padding: '0.5rem 1rem',
        }}
      >
        <Typography fontWeight="bold">
          {invoiceDetails.length} Invoices
        </Typography>
        <Box>
          <Button sx={{ px: spacing(2) }} type="submit">
            Send Request
          </Button>
        </Box>
      </Flex>
    </form>
  );
};

export default UploadInvoices;
