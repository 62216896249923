import { Box, alpha } from '@mui/system';
import React, { useState, useEffect, useMemo } from 'react';
import {
  Flex,
  FetchingSkeleton,
  Table,
  TextField,
  Typography,
  BuyerLogo,
  Dialog,
} from 'src/components';
import {
  INVOICE_STATUS_FILTER_ARRAY,
  INVOICE_STATUS_FILTER_ENUM,
  INVOICES_FILTER,
  INVOICES_FILTER_DROPDOWN_ARRAY,
} from 'src/constants';
import { useLazyFetchAllInvoicesHistoryQuery } from 'src/redux/services/invoices';
import {
  getSupplierId,
  useAppSelector,
  useAppDispatch,
  toggleSnackbar,
} from 'src/redux';
import { ButtonBase, Select, SelectChangeEvent } from '@mui/material';
import { MenuItem } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { getDMY } from 'src/utils';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme } from '@mui/material';
import { COLORS } from 'src/theme/constants';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { PAYMENT_STATUS } from 'src/constants';
import { TableColumnType } from 'src/components/common/Table';
import { InvoicesType } from 'src/types';
import { rupeeDecimalFormatter } from 'src/utils';
import {
  PaidCardModal,
  UnpaidCardModal,
  FailedCardModal,
} from './InvoiceModal';

type InvoiceViewProps = {
  startDate: string | null;
  endDate: string | null;
  resetDate: () => void;
};

export default function InvoiceView({
  startDate,
  endDate,
  resetDate,
}: InvoiceViewProps) {
  const { spacing, palette } = useTheme();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState<INVOICES_FILTER | string>('');
  const [searchValue, setSearchValue] = useState('');
  const supplierId = useAppSelector(getSupplierId);
  const [currentFilter, setcurrentFilter] = useState(
    INVOICE_STATUS_FILTER_ENUM.UNPAID
  );
  const [multipleInvoiceData, setMultipleInvoiceData] = useState<{
    isVisible: boolean;
    data: InvoicesType | null;
  }>({
    isVisible: false,
    data: null,
  });
  const dispatch = useAppDispatch();
  useEffect(() => {
    invoicesFetch({
      endDate: endDate ? endDate : '',
      pageNumber,
      searchParam,
      startDate: startDate ? startDate : '',
      type: currentFilter,
      searchValue,
      supplierId,
    });
  }, [pageNumber]);

  useEffect(() => {
    invoicesFetch({
      endDate: endDate ? endDate : '',
      pageNumber: 1,
      searchParam,
      startDate: startDate ? startDate : '',
      type: currentFilter,
      searchValue,
      supplierId,
    });
    setPageNumber(1);
  }, [currentFilter, endDate, startDate]);

  const [
    invoicesFetch,
    { data: allInvoicesHistoryData, isFetching: isAllInvoicesHistoryFetching },
  ] = useLazyFetchAllInvoicesHistoryQuery();

  //HANDLERS
  const onFilterChange = (event: SelectChangeEvent) => {
    setSearchParam(event.target.value as INVOICES_FILTER);
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    setPageNumber(1);
  };

  const onMultipleInvoiceClick = (row: InvoicesType) => {
    setMultipleInvoiceData({
      isVisible: true,
      data: row,
    });
  };

  const resetMultipleInvoice = () => {
    setMultipleInvoiceData({
      isVisible: false,
      data: null,
    });
  };

  const onSearch = () => {
    if (searchParam) {
      invoicesFetch({
        endDate: endDate ? endDate : '',
        pageNumber,
        searchParam,
        startDate: startDate ? startDate : '',
        type: currentFilter,
        searchValue: searchValue.trim(),
        supplierId,
      });
    } else {
      dispatch(
        toggleSnackbar({
          showSnackBar: true,
          snackBarMessage: 'Please select search type',
          snackBarType: 'error',
        })
      );
    }
  };

  const onToggleChange = (
    _: React.MouseEvent<HTMLDivElement>,
    filterName: INVOICE_STATUS_FILTER_ENUM
  ) => {
    setcurrentFilter(filterName);
    setSearchParam('');
    setSearchValue('');
  };

  const handleChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };

  const resetFilters = () => {
    setSearchParam('');
    setSearchValue('');
    setPageNumber(1);
    resetDate();
    invoicesFetch({
      endDate: '',
      pageNumber: 1,
      searchParam: '',
      startDate: '',
      type: currentFilter,
      searchValue: '',
      supplierId,
    });
  };

  // Table Column : Unpaid Invoice Table
  const unpaidHeaders: TableColumnType<InvoicesType>[] = [
    {
      label: 'Party Name',
      id: 'partyName',
      render: ({ row }) => {
        return (
          <Typography fontSize="0.8rem">
            {row?.partyName ? (
              <Flex className="gap-2" alignItems={'center'}>
                <BuyerLogo name={row?.partyName} />
                {row?.partyName}
              </Flex>
            ) : (
              '-'
            )}
          </Typography>
        );
      },
    },
    {
      label: 'Invoice Number',
      id: 'invoiceNumber',
      render: ({ row }) => {
        const { palette } = useTheme();
        return (
          <Flex
            className="gap-2"
            alignItems={'center'}
            onClick={() => onMultipleInvoiceClick(row)}
            sx={{ cursor: 'pointer' }}
          >
            <DescriptionOutlinedIcon
              sx={{
                color: COLORS.PRIMARY,
                backgroundColor: alpha(palette.primary.main, 0.2),
                borderRadius: '0.2rem',
              }}
              fontSize="medium"
            />
            <Typography color="primary" fontWeight="bold" fontSize="0.8rem">
              {row?.invoiceNumber}
            </Typography>
          </Flex>
        );
      },
    },
    {
      label: 'Uploaded Date',
      id: 'paymentDate',
      render: ({ row }) => {
        return <div>{row?.invoiceUploadDate || '-'}</div>;
      },
    },
    {
      label: 'Invoice amount',
      id: 'invoiceAmount',
      render: ({ row }) => {
        if (typeof row?.invoiceAmount === 'number') {
          return <div>{rupeeDecimalFormatter(row?.invoiceAmount)}</div>;
        }
        return <div>{row?.invoiceAmount || '-'}</div>;
      },
    },
  ];

  // Table Column : Failed Invoice Table
  const failedHeaders: TableColumnType<InvoicesType>[] = [
    {
      label: 'Party Name',
      id: 'partyName',
      render: ({ row }) => {
        return (
          <Typography fontSize="0.8rem">
            {row?.partyName ? (
              <Flex className="gap-2" alignItems={'center'}>
                <BuyerLogo name={row?.partyName} />
                {row?.partyName}
              </Flex>
            ) : (
              '-'
            )}
          </Typography>
        );
      },
    },
    {
      label: 'Invoice Number',
      id: 'invoiceNumber',
      render: ({ row }) => {
        const { palette } = useTheme();
        return (
          <Flex
            className="gap-2"
            alignItems={'center'}
            onClick={() => onMultipleInvoiceClick(row)}
            sx={{ cursor: 'pointer' }}
          >
            <DescriptionOutlinedIcon
              sx={{
                color: COLORS.PRIMARY,
                backgroundColor: alpha(palette.primary.main, 0.2),
                borderRadius: '0.2rem',
              }}
              fontSize="medium"
            />
            <Typography color="primary" fontWeight="bold" fontSize="0.8rem">
              {row?.invoiceNumber}
            </Typography>
          </Flex>
        );
      },
    },
    {
      label: 'Uploaded Date',
      id: 'invoiceUploadDate',
      render: ({ row }) => {
        return (
          <div className="capitalize">{row?.invoiceUploadDate || '-'}</div>
        );
      },
    },
    {
      label: 'Invoice amount',
      id: 'invoiceAmount',
      render: ({ row }) => {
        if (typeof row?.invoiceAmount === 'number') {
          return <div>{rupeeDecimalFormatter(row?.invoiceAmount)}</div>;
        }
        return <div>{row?.invoiceAmount || '-'}</div>;
      },
    },
    {
      label: 'Reason',
      id: 'failReason',
    },
  ];

  // Table Column : Paid Invoice Table
  const paidHeaders: TableColumnType<InvoicesType>[] = [
    {
      label: 'Party Name',
      id: 'partyName',
      render: ({ row }) => {
        return (
          <Typography fontSize="0.8rem">
            {row?.partyName ? (
              <Flex className="gap-2" alignItems={'center'}>
                <BuyerLogo name={row?.partyName} />
                {row?.partyName}
              </Flex>
            ) : (
              '-'
            )}
          </Typography>
        );
      },
    },
    {
      label: 'Invoice Number',
      id: 'invoiceNumber',
      render: ({ row }) => {
        const { palette } = useTheme();
        return (
          <Flex
            className="gap-2"
            alignItems={'center'}
            onClick={() => onMultipleInvoiceClick(row)}
            sx={{ cursor: 'pointer' }}
          >
            <DescriptionOutlinedIcon
              sx={{
                color: COLORS.PRIMARY,
                backgroundColor: alpha(palette.primary.main, 0.2),
                borderRadius: '0.2rem',
              }}
              fontSize="medium"
            />
            <Typography color="primary" fontWeight="bold" fontSize="0.8rem">
              {row?.invoiceNumber}
            </Typography>
          </Flex>
        );
      },
    },
    {
      label: 'Uploaded By',
      id: 'uploadedBy',
      render: ({ row }) => {
        return <div className="capitalize">{row?.uploadedBy || '-'}</div>;
      },
    },
    {
      label: 'Payment amount',
      id: 'paymentAmount',
      render: ({ row }) => {
        if (typeof row?.paymentAmount === 'number') {
          return <div>{rupeeDecimalFormatter(row?.paymentAmount)}</div>;
        }
        return <div>{row?.paymentAmount || '-'}</div>;
      },
    },
    {
      label: 'Payment Date',
      id: 'paymentDate',
      render: ({ row }) => {
        return <div>{row?.paymentDate || '-'}</div>;
      },
    },
    {
      label: 'UTR',
      id: 'utrNumber',
      render: ({ row }) => {
        const { palette } = useTheme();
        return (
          <>
            <div>{row?.utrNumber || '-'}</div>
            <Flex className="gap-2" alignItems={'center'}>
              {row?.paymentStatus === PAYMENT_STATUS.PROCESSED ? (
                <>
                  <CheckCircleIcon
                    fontSize="medium"
                    sx={{
                      color: palette.lightGreen,
                    }}
                  />
                  <div>Processed</div>
                </>
              ) : row?.paymentStatus === PAYMENT_STATUS.PROCESSING ||
                row?.paymentStatus === PAYMENT_STATUS.PAYOUT_FAILED ||
                row?.paymentStatus === PAYMENT_STATUS.PENDING ? (
                <>
                  <AccessTimeFilledIcon
                    fontSize="medium"
                    sx={{
                      color: palette.orange,
                    }}
                  />
                  <div>Processing</div>
                </>
              ) : null}
            </Flex>
          </>
        );
      },
    },
    {
      label: 'Repayment Status',
      id: 'repaymentStatus',
      render: ({ row }) => {
        const { palette } = useTheme();
        return (
          <>
            <Flex className="gap-2" alignItems={'center'}>
              {row?.settled ? (
                <CheckCircleIcon
                  fontSize="medium"
                  sx={{
                    color: palette.lightGreen,
                  }}
                />
              ) : row?.overdue ? (
                <WarningRoundedIcon
                  fontSize="medium"
                  sx={{
                    color: palette.red,
                  }}
                />
              ) : (
                <WarningRoundedIcon
                  fontSize="medium"
                  sx={{
                    color: palette.orange,
                  }}
                />
              )}
              <div>{row?.repaymentStatus || '-'}</div>
            </Flex>
          </>
        );
      },
    },
  ];

  const getCurrentFilter = useMemo(() => {
    const filterKeymap = {
      unpaid: unpaidHeaders,
      paid: paidHeaders,
      failed: failedHeaders,
    };
    return filterKeymap[currentFilter];
  }, [currentFilter]);

  return (
    <>
      <Flex justifyContent={'space-between'} alignItems={'center'}>
        <Box>
          <Flex sx={{ my: spacing(2) }}>
            {INVOICE_STATUS_FILTER_ARRAY.map((filterName) => {
              const isActive = filterName.value === currentFilter;
              return (
                <Box
                  sx={{
                    border: `1px solid ${
                      isActive ? palette.primary.main : palette.lightGray
                    }`,
                    borderRadius: 10,
                    p: `${spacing(0.5)} ${spacing(2.5)}`,
                    mr: spacing(1.2),
                    fontSize: '1rem',
                    color: `${
                      isActive ? palette.primary.main : palette.ghostGray
                    }`,
                    backgroundColor: isActive
                      ? alpha(palette.primary.main, 0.2)
                      : 'transparent',
                    cursor: 'pointer',
                  }}
                  onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                    onToggleChange(event, filterName.value)
                  }
                  key={filterName.value}
                >
                  {filterName.label}
                </Box>
              );
            })}
          </Flex>
        </Box>
        <Flex className="gap-4">
          <Select
            value={searchParam}
            onChange={onFilterChange}
            sx={{
              '.MuiSelect-select': {
                padding: spacing(2),
                width: spacing(22),
                background: 'white',
                borderRadius: '100px',
              },
            }}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value="" disabled>
              <em>Search By </em>
            </MenuItem>
            {INVOICES_FILTER_DROPDOWN_ARRAY.map((dropdownVal) => {
              return (
                <MenuItem value={dropdownVal.value} key={dropdownVal.value}>
                  {dropdownVal.label}
                </MenuItem>
              );
            })}
          </Select>
          <Box className="relative">
            <TextField
              value={searchValue || ''}
              onChange={onInputChange}
              placeholder="Search"
              className="absolute"
              onKeyDown={(event) => {
                //This event is triggered every time the user presses down any key while selecting the text input field.
                if (event.key === 'Enter') {
                  event.preventDefault(); // Prevent the form from submitting
                  onSearch(); // Call the onSearch function when Enter key is pressed
                }
              }}
              sx={{
                width: '250px',
              }}
            />
            <ButtonBase
              sx={{
                position: 'absolute',
                top: '50%',
                right: 15,
                transform: 'translateY(-50%)',
                color: palette.primary.main,
                fontWeight: 'bold',
                fontSize: '12px',
              }}
              onClick={onSearch}
            >
              Search
            </ButtonBase>
          </Box>
          <Box
            sx={{
              borderRadius: 5,
              p: `${spacing(0.5)} ${spacing(1.5)}`,
              mr: spacing(1.2),
              fontSize: '1rem',
              fontWeight: 'bold',
              color: palette.primary.main,
              backgroundColor: alpha(palette.primary.main, 0.2),
              cursor: 'pointer',
              height: '2.2rem',
              marginTop: '0.6rem',
            }}
            onClick={resetFilters}
          >
            Reset Filter
          </Box>
        </Flex>
      </Flex>
      {isAllInvoicesHistoryFetching ? (
        <FetchingSkeleton />
      ) : (
        <Table
          rows={allInvoicesHistoryData?.invoiceDetails ?? []}
          columns={getCurrentFilter}
          paginationComponent={
            <Pagination
              count={allInvoicesHistoryData?.numberOfPages}
              page={pageNumber}
              onChange={handleChange}
            />
          }
          className="mt-4"
        />
      )}

      <Dialog
        open={multipleInvoiceData.isVisible}
        onClose={resetMultipleInvoice}
        heading={`Invoice Number : ${multipleInvoiceData.data?.invoiceNumber} `}
      >
        {currentFilter === INVOICE_STATUS_FILTER_ENUM.PAID ? (
          <PaidCardModal invoiceData={multipleInvoiceData.data} />
        ) : currentFilter === INVOICE_STATUS_FILTER_ENUM.UNPAID ? (
          <UnpaidCardModal invoiceData={multipleInvoiceData.data} />
        ) : (
          <FailedCardModal invoiceData={multipleInvoiceData.data} />
        )}
      </Dialog>
    </>
  );
}
