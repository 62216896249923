import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { withPageInternals } from 'src/hoc';
import { Box } from '@mui/system';
import UserProfile from './UserProfile';
import Program from './Program';
import {
  Typography,
  Flex,
  PageHeading,
  FetchingSkeleton,
} from 'src/components';
import { useTheme, alpha } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { HOME } from 'src/utils';
import { ArrowBack } from '@mui/icons-material';
import {
  useFetchSupplierProfileDetailsQuery,
  getSupplierId,
  useAppSelector,
  useFetchSupplierProgramDetailsQuery,
  getPartyUniqueId,
  getMobileNumber,
} from 'src/redux';

const Profile = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { palette, spacing } = useTheme();
  const navigate = useNavigate();
  const supplierId = useAppSelector(getSupplierId);
  const mobileNumber = useAppSelector(getMobileNumber);
  const uniqueSupplierId = useAppSelector(getPartyUniqueId);
  const { data: profileData, isFetching: isProfileDataFetching } =
    useFetchSupplierProfileDetailsQuery({ mobileNumber });
  const { data: programData, isFetching: isProgramDataFetching } =
    useFetchSupplierProgramDetailsQuery({ uniqueSupplierId });

  if (isProfileDataFetching && isProgramDataFetching) {
    return <FetchingSkeleton />;
  }

  const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };
  return (
    <>
      <Flex justifyContent="space-between">
        <Flex alignItems="center">
          <ArrowBack
            onClick={() => {
              navigate(`${HOME}?activetab=dashboard`);
            }}
            sx={{ cursor: 'pointer' }}
          />
          <PageHeading sx={{ marginLeft: spacing(1) }}>Profile</PageHeading>
        </Flex>
      </Flex>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Flex
          sx={{
            padding: spacing(1),
            borderRadius: '100px',
            height: spacing(15),
            width: spacing(15),
            margin: spacing(2),
            background: alpha(palette.primary.main, 0.15),
          }}
          alignItems="center"
          justifyContent="center"
        >
          <Typography color="primary" fontWeight="bold" fontSize="1.2rem">
            {profileData?.supplierName?.[0] ?? ''}
          </Typography>
        </Flex>
        <Typography>{profileData?.gstDetails?.tradeName}</Typography>
        <Typography>{profileData?.gstDetails?.entityName}</Typography>
        <Typography>GSTN: {profileData?.gstDetails?.gstin}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Tabs
          value={activeTab}
          onChange={onTabChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="basic tabs example"
        >
          <Tab label="Profile" />

          {programData?.programs?.map((e, index) => {
            return (
              <Tab label={`Program ${index + 1}`} key={`Tab-${index + 1}`} />
            );
          })}
        </Tabs>
      </Box>
      <Box mt={2}>
        {activeTab === 0 && <UserProfile />}
        {programData?.programs?.map((program, index) => {
          return (
            activeTab === index + 1 && (
              <Program key={`Program-${index + 1}`} program={program} />
            )
          );
        })}
      </Box>
    </>
  );
};

export default withPageInternals(Profile);
