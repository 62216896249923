import { RootState } from 'src/redux/store';
export const getMobileNumber = (state: RootState) => state.user.mobileNumber;
export const getAccessToken = (state: RootState) => state.user.userBEData.token;
export const getAccessUserId = (state: RootState) =>
  state.user.userBEData.userId;
export const getSupplierId = (state: RootState) =>
  state.user.userBEData.partyUniqueId; // added this part for temporary basis getSupplierId===getPartyUniqueId
export const getPartyUniqueId = (state: RootState) =>
  state.user.userBEData.partyUniqueId;
export const getUser = (state: RootState) => state.user;
