import { invoiceApi } from './services/invoices/query';
import { homeApi, homeApiC1 } from './services/home/query';
import { profileApi, profileApiC1 } from './services/profile/query';
import { usersSlice } from './slices/user/slices';
import { homeSlice } from './slices/home/slices';
import { internalsSlice } from 'src/redux/slices';
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { activePartiesApi } from './services';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'], // Only this reducer will store in the local storage and we will store user data only
};

export const store = configureStore({
  reducer: persistReducer(
    persistConfig,
    combineReducers({
      [internalsSlice.name]: internalsSlice.reducer,
      [homeSlice.name]: homeSlice.reducer,
      [usersSlice.name]: usersSlice.reducer,
      [homeApi.reducerPath]: homeApi.reducer,
      [homeApiC1.reducerPath]: homeApiC1.reducer,
      [invoiceApi.reducerPath]: invoiceApi.reducer,
      [profileApi.reducerPath]: profileApi.reducer,
      [profileApiC1.reducerPath]: profileApiC1.reducer,
      [activePartiesApi.reducerPath]: activePartiesApi.reducer,
    })
  ),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      homeApi.middleware,
      invoiceApi.middleware,
      profileApi.middleware,
      profileApiC1.middleware,
      activePartiesApi.middleware,
      homeApiC1.middleware
    ),
  devTools: process.env.NODE_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
