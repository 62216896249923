export const API_STATUS_CODE = {
  OK: 200,
  UNAUTHORISED: 401,
  INVALID_REQUEST: 400,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  INTERNAL_ERROR: 500,
  BACKEND_ERROR: 999,
};
export const API_STATUS_MESSAGE = {
  OK: 'OK',
  UNAUTHORISED: 'UNAUTHORISED',
  INVALID_REQUEST: 'INVALID_REQUEST',
  NOT_FOUND: 'NOT_FOUND',
  METHOD_NOT_ALLOWED: 'METHOD_NOT_ALLOWED',
  INTERNAL_ERROR: 'INTERNAL_ERROR',
};

export const REQUEST_SOURCE = 'OPSDU';
