export const ERRORS = {
  SOMETHING_WRONG: 'SomeThing Went Wrong',
  INVOICE_SEND: 'Invoices Succesfully Send',
  EXCEL_UPLOADED: 'Excel Uploaded Successfully',
  INVOICE_UPLOADED: 'Invoice Uploaded Successfully',
  USER_NOT_REGISTER: 'User not register, Please use different mobile number',
  INVALID_OTP: 'Invalid OTP, Please enter correct OTP',
  INVALID_BUYER_SEARCH_INPUT:
    'Please enter correct Mobile Number(10 digits) or GST Number(15 characters)',
  DATA_NOT_FOUND: "We don't have any record with current inputs",
  MOBILE_NUMBER_ALERT: 'Please enter 10 digit mobile number',
  OTP_LENGTH: 'Please enter correct 4 digit otp',
};
