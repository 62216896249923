import CurrencyRupee from '@mui/icons-material/CurrencyRupee';
import { Box } from '@mui/system';

export const rupeeDecimalFormatter = (
  value: number | null | undefined,
  fontSize = '0.8rem'
) => {
  let amount = value && value.toString();
  if (!amount) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <CurrencyRupee sx={{ fontSize }} />0
      </Box>
    );
  }
  let afterPoint = '';
  if (amount?.indexOf('.') > 0)
    afterPoint = amount
      ?.substring(amount?.indexOf('.'), amount?.length)
      .slice(0, 3);
  amount = Math.floor(+amount).toString();
  let lastThree = amount.substring(amount?.length - 3);
  const otherNumbers = amount.substring(0, amount?.length - 3);
  if (otherNumbers !== '') lastThree = ',' + lastThree;
  const res =
    otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree + afterPoint;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 0.25,
      }}
    >
      <CurrencyRupee sx={{ fontSize }} />
      {res}
    </Box>
  );
};

export const getDMY = (dateStr: Date) => {
  const dateObj = new Date(dateStr);
  const date = dateObj.getDate();
  const month = dateObj.getMonth() + 1;
  const year = dateObj.getFullYear();
  return `${date}/${month}/${year}`;
};

export const integerToWordFormatterLabels = (value: number) => {
  if (!value) {
    return '0';
  }
  const stringifyValue = value.toString();
  if (stringifyValue.length >= 3 && stringifyValue.length <= 5) {
    return (value / 1000).toFixed(2) + 'K';
  }

  if (stringifyValue.length > 5 && stringifyValue.length <= 7) {
    return (value / 100000).toFixed(2) + ' Lakh';
  }

  if (stringifyValue.length > 7) {
    return (value / 10000000).toFixed(2) + ' Crore';
  }
};
