import { alpha } from '@mui/material';
import { COLORS } from 'src/theme/constants';

export enum Filters {
  GST = 'gst',
  MOBILE_NUMBER = 'mobileNumber',
}

export const FILTERS_KEY_MAP = {
  [Filters.MOBILE_NUMBER]: 'Mobile Number',
  [Filters.GST]: 'Gst Number',
};

export const FILTERS_DROPDOWN_ARRAY = [
  {
    label: FILTERS_KEY_MAP[Filters.GST],
    value: Filters.GST,
  },
  {
    label: FILTERS_KEY_MAP[Filters.MOBILE_NUMBER],
    value: Filters.MOBILE_NUMBER,
  },
];

export const BUYER_LIMIT_STATUS = {
  ACTIVE: 'Active',
  ON_HOLD: 'On Hold',
  INACTIVE: 'Inactive',
  BLOCKED: 'Blocked',
};

export const BUYER_LIMIT_STATUS_CAPSULE = [
  {
    status: BUYER_LIMIT_STATUS.ACTIVE,
    colour: COLORS.DARK_GREEN,
    backgroundColor: alpha(COLORS.DARK_GREEN, 0.1),
  },
  {
    status: BUYER_LIMIT_STATUS.ON_HOLD,
    colour: COLORS.ORANGE,
    backgroundColor: alpha(COLORS.ORANGE, 0.1),
  },
  {
    status: BUYER_LIMIT_STATUS.BLOCKED,
    colour: COLORS.ERROR,
    backgroundColor: alpha(COLORS.ERROR, 0.1),
  },
  {
    status: BUYER_LIMIT_STATUS.INACTIVE,
    colour: COLORS.BLACK,
    backgroundColor: alpha(COLORS.BLACK, 0.1),
  },
];
