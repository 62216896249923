import {
  FecthActivePartiesRespType,
  FetchBuyerDetailsArgsType,
  FetchBuyerDetailsDataType,
  FetchBuyerDetailsRespType,
  FetchBuyerInvoiceDetailsDataType,
  FetchBuyerInvoiceDetailsArgsType,
  FetchBuyerInvoiceDetailsRespType,
  FetchBuyerPaymentDetailsDataType,
  FetchBuyerPaymentDetailsArgsType,
  FetchBuyerPaymentDetailsRespType,
} from 'src/types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseApiBEQueryC1 } from 'src/utils';
import { FecthActivePartiesArgsType } from 'src/types';

export const activePartiesApi = createApi({
  reducerPath: 'activeParties',
  baseQuery: baseApiBEQueryC1,
  endpoints: (builder) => ({
    fetchActiveParties: builder.query<
      FecthActivePartiesRespType,
      FecthActivePartiesArgsType
    >({
      query: (body) => ({
        url: 'invoice-management/v2/dashboard/active-parties',
        method: 'POST',
        body,
      }),
    }),
    fetchBuyerDetails: builder.query<
      FetchBuyerDetailsDataType,
      FetchBuyerDetailsArgsType
    >({
      query: ({ supplierId, userId }) => ({
        url: `invoice-management/v2/dashboard/invoice-summary/${supplierId}/${userId}`,
        method: 'GET',
      }),
      transformResponse: (response: FetchBuyerDetailsRespType) => {
        return response.data;
      },
    }),
    fetchBuyerInvoiceDetails: builder.query<
      FetchBuyerInvoiceDetailsDataType,
      FetchBuyerInvoiceDetailsArgsType
    >({
      query: ({ supplierId, userId, ...body }) => ({
        url: `invoice-management/v2/dashboard/invoice-list/${supplierId}/${userId}`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: FetchBuyerInvoiceDetailsRespType) => {
        return response.data;
      },
    }),
    fetchBuyerPaymentDetails: builder.query<
      FetchBuyerPaymentDetailsDataType,
      FetchBuyerPaymentDetailsArgsType
    >({
      query: (body) => ({
        url: `invoice-management/v2/dashboard/fetch/payment-history`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: FetchBuyerPaymentDetailsRespType) => {
        return response.data;
      },
    }),
  }),
});

export const {
  useLazyFetchActivePartiesQuery,
  useFetchActivePartiesQuery,
  useFetchBuyerDetailsQuery,
  useLazyFetchBuyerInvoiceDetailsQuery,
  useLazyFetchBuyerPaymentDetailsQuery,
} = activePartiesApi;
